import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MailIcon from '@mui/icons-material/Mail';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIssue } from '../../../hooks/useIssue';
import { useNotification } from '../../../hooks/useNotification';
import { ECriteriaExpression, EPriority, IBaseActionBtn, ICriteria, IFilter } from '../../../models/CommonModels';
import { EIssueStatus, EIssueType } from '../../../models/IssueModels';
import IssueService from '../../../services/IssueService';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';
import IssueResolveDialog from '../IssueResolveDialog';

const getPriorityColor = (value: EPriority) => {
    let color: string = '';
    switch (value) {
        case EPriority.LOW: {
            color = 'rgb(27, 94, 32)'
            break;
        }
        case EPriority.MEDIUM: {
            color = '#ed6c02';
            break;
        }
        case EPriority.HIGH: {
            color = 'rgb(198, 40, 40)';
            break;
        }
    }
    return color;
};

interface IProps {
    data: any;
}
const IssueStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useIssue();
    const [resolveDialogToggle, setResolveDialogToggle] = useState(false);

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <PlaylistAddCheckIcon style={{ color: 'rgb(106, 104, 104)' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>#{data.idno}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px' }}>
                    <b>{data.status.toString().replaceAll('_', ' ')}</b>
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.type.toString().replaceAll('_', ' ')}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px', color: getPriorityColor(data.priority) }}>
                    <b>{EPriority[data.priority]}</b>
                </Typography>
            </Stack>

            {data.type !== EIssueType.STANDARD &&
                <Divider
                    orientation='vertical'
                    flexItem sx={{ marginLeft: '10px', marginRight: '10px' }}
                />
            }

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    {data.type === EIssueType.TRANSPORT &&
                        <b>{`${data.transport.brand} ${data.transport.model}`.toUpperCase()}</b>
                    }

                    {data.type === EIssueType.ACTIVE_LOAD &&
                        <Link to={`/activeLoad/${data.activeLoad.uuid}`}>
                            {`#${data.activeLoad.idno.toUpperCase()}`}
                        </Link>
                    }
                </Typography>

                <Typography sx={{ fontSize: '13px' }}>
                    {data.type === EIssueType.TRANSPORT &&
                        <Link to={`/transport/${data.transport.uuid}`}>
                            {`${data.transport.licensePlate} [ ${data.transport.transportNumber} ]`.toUpperCase()}
                        </Link>
                    }

                    {data.type === EIssueType.ACTIVE_LOAD && data.activeLoad.name
                        ? <b>{data.activeLoad.name.toUpperCase()}</b> : <b>&nbsp;</b>
                    }
                </Typography>
            </Stack>
        </Stack>
    </Box>;

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return IssueService.downloadReport(filter);
    }, [data.uuid]);

    const inProgressStatus: boolean = data.status === EIssueStatus.IN_PROGRESS;
    const completedStatus: boolean = data.status === EIssueStatus.DONE || data.status === EIssueStatus.UNRESOLVED;
    const noAssignedUser: boolean = !data.assignedOn;

    const onInProgressActionBtnClickHandler = useCallback(() => {
        if (data) {
            (async () => {
                const [error] = await IssueService.markInProgress(data.uuid);

                if (error) {
                    displayNotification({ type: 'error', message: error?.message });
                } else {
                    displayNotification({ message: t('Issue was successfully marked in progress.') });
                    gridRefresh();
                    stepRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, gridRefresh, stepRefresh, t]);

    const onResolveActionBtnClickHandler = useCallback(() => {
        setResolveDialogToggle(true);
    }, []);

    const closeResolveDialogHandler = useCallback(() => {
        setResolveDialogToggle(false);
    }, []);

    const onSubmitResolveDialogHandler = useCallback(() => {
        gridRefresh();
        stepRefresh();
    }, [gridRefresh, stepRefresh]);

    const actionBtns: IBaseActionBtn[] = [{
        tooltip: t('Start working on the item'),
        onClick: onInProgressActionBtnClickHandler,
        icon: <PublishedWithChangesIcon />,
        disabled: completedStatus || inProgressStatus || noAssignedUser,
        key: 'issue-mark-in-progress-action-btn',
        confirmation: true
    }, {
        tooltip: t('Resolve item'),
        onClick: onResolveActionBtnClickHandler,
        icon: <CheckCircleIcon />,
        disabled: !inProgressStatus,
        key: 'issue-resolve-action-btn'
    }, {
        tooltip: t('Chat'),
        onClick: () => console.log('Open chat'),
        icon: <MailIcon />,
        disabled: true,
        key: 'issue-chat-action-btn'
    }];

    return (
        <>
            <BaseStepCoverFace
                content={content}
                favoriteBtnHide
                downloadBtnTooltip={t('Download load')}
                downloadApi={onDownloadApiHandler}
                actionBtns={actionBtns}
            />

            {resolveDialogToggle && data &&
                <IssueResolveDialog
                    open={resolveDialogToggle}
                    issueId={data.uuid}
                    idno={data.idno}
                    onCloseBtnClick={closeResolveDialogHandler}
                    onSubmitBtnClick={onSubmitResolveDialogHandler}
                />
            }
        </>
    );
}
export default IssueStepCoverFace;